import { DateTime } from 'luxon';

import { Transaction, User } from '.';

export default class Invoice {
  static thaw(invoiceObj) {
    if (invoiceObj == null) return null;
    const invoice = new Invoice();
    if (invoiceObj.id != null) {
      invoice.setId(invoiceObj.id);
    }
    if (invoiceObj.invoiceNumber != null) {
      invoice.setInvoiceNumber(invoiceObj.invoiceNumber);
    }
    if (invoiceObj.dueDate != null) {
      invoice.setDueDate(DateTime?.fromISO(invoiceObj.dueDate) ?? null);
    }
    if (invoiceObj.customerName != null) {
      invoice.setCustomerName(invoiceObj.customerName);
    }
    if (invoiceObj.notes != null) {
      invoice.setNotes(invoiceObj.notes);
    }
    if (invoiceObj.isPaid != null) {
      invoice.setIsPaid(invoiceObj.isPaid == null ? null : (invoiceObj.isPaid ? true : false));
    }
    if (invoiceObj.isActive != null) {
      invoice.setIsActive(invoiceObj.isActive == null ? null : (invoiceObj.isActive ? true : false));
    }
    if (invoiceObj.token != null) {
      invoice.setToken(invoiceObj.token);
    }
    if (invoiceObj.amount != null) {
      invoice.setAmount(invoiceObj.amount);
    }
    if (invoiceObj.createdAt != null) {
      invoice.setCreatedAt(DateTime?.fromISO(invoiceObj.createdAt) ?? null);
    }
    if (invoiceObj.updatedAt != null) {
      invoice.setUpdatedAt(DateTime?.fromISO(invoiceObj.updatedAt) ?? null);
    }
    if (invoiceObj.createdByUserId != null) {
      invoice.setCreatedByUserId(invoiceObj.createdByUserId);
    }
    if (invoiceObj.transactions != null) {
      invoice.setTransactions(Transaction.thawList(invoiceObj.transactions));
    }
    if (invoiceObj.createdByUser != null) {
      invoice.setCreatedByUser(User.thaw(invoiceObj.createdByUser));
    }
    if (invoiceObj.email != null) {
      invoice.setEmail(invoiceObj.email);
    }
    return invoice;
  }

  static thawList(invoiceObjList) {
    if (invoiceObjList == null) {
      return [];
    }
    const invoices = [];
    for (let i = 0; i < invoiceObjList.length; i++) {
      invoices.push(Invoice.thaw(invoiceObjList[i]));
    }
    return invoices;
  }

  static freeze(invoice) {
    if (invoice == null) {
      return null;
    }
    const invoiceObj = {};
    if (invoice.getId() != null) {
      invoiceObj.id = invoice.getId();
    }
    if (invoice.getInvoiceNumber() != null) {
      invoiceObj.invoiceNumber = invoice.getInvoiceNumber();
    }
    if (invoice.getDueDate() != null) {
      invoiceObj.dueDate = invoice.getDueDate()?.toISO() ?? null;
    }
    if (invoice.getCustomerName() != null) {
      invoiceObj.customerName = invoice.getCustomerName();
    }
    if (invoice.getNotes() != null) {
      invoiceObj.notes = invoice.getNotes();
    }
    if (invoice.getIsPaid() != null) {
      invoiceObj.isPaid = invoice.getIsPaid() == null ? null : (invoice.getIsPaid() ? 1 : 0);
    }
    if (invoice.getIsActive() != null) {
      invoiceObj.isActive = invoice.getIsActive() == null ? null : (invoice.getIsActive() ? 1 : 0);
    }
    if (invoice.getToken() != null) {
      invoiceObj.token = invoice.getToken();
    }
    if (invoice.getAmount() != null) {
      invoiceObj.amount = invoice.getAmount();
    }
    if (invoice.getCreatedAt() != null) {
      invoiceObj.createdAt = invoice.getCreatedAt()?.toISO() ?? null;
    }
    if (invoice.getUpdatedAt() != null) {
      invoiceObj.updatedAt = invoice.getUpdatedAt()?.toISO() ?? null;
    }
    if (invoice.getCreatedByUserId() != null) {
      invoiceObj.createdByUserId = invoice.getCreatedByUserId();
    }
    if (invoice.getTransactions() != null) {
      invoiceObj.transactions = Transaction.freezeList(invoice.getTransactions());
    }
    if (invoice.getCreatedByUser() != null) {
      invoiceObj.createdByUser = User.freeze(invoice.getCreatedByUser());
    }
    if (invoice.getEmail() != null) {
      invoiceObj.email = invoice.getEmail();
    }
    return invoiceObj;
  }

  static freezeList(invoices) {
    if (invoices == null) {
      return [];
    }
    const invoiceObjList = [];
    for (let i = 0; i < invoices.length; i++) {
      invoiceObjList.push(Invoice.freeze(invoices[i]));
    }
    return invoiceObjList;
  }

  constructor() {
    this.id = null;
    this.invoiceNumber = null;
    this.dueDate = null;
    this.customerName = null;
    this.notes = null;
    this.isPaid = null;
    this.isActive = null;
    this.token = null;
    this.amount = null;
    this.createdAt = null;
    this.updatedAt = null;
    this.createdByUserId = null;
    this.transactions = null;
    this.createdByUser = null;
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setId(id) {
    this.id = id;
  }

  setInvoiceNumber(invoiceNumber) {
    this.invoiceNumber = invoiceNumber;
  }

  setDueDate(dueDate) {
    this.dueDate = dueDate;
  }

  setCustomerName(customerName) {
    this.customerName = customerName;
  }

  setNotes(notes) {
    this.notes = notes;
  }

  setIsPaid(isPaid) {
    this.isPaid = isPaid;
  }

  setIsActive(isActive) {
    this.isActive = isActive;
  }

  setToken(token) {
    this.token = token;
  }

  setAmount(amount) {
    this.amount = amount;
  }

  setCreatedAt(createdAt) {
    this.createdAt = createdAt;
  }

  setUpdatedAt(updatedAt) {
    this.updatedAt = updatedAt;
  }

  setCreatedByUserId(createdByUserId) {
    this.createdByUserId = createdByUserId;
  }

  setTransactions(transactions) {
    this.transactions = transactions;
  }

  setCreatedByUser(createdByUser) {
    this.createdByUser = createdByUser;
  }

  setEmail(email) {
    this.email = email;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getId() {
    return this.id;
  }

  getInvoiceNumber() {
    return this.invoiceNumber;
  }

  getDueDate() {
    return this.dueDate;
  }

  getCustomerName() {
    return this.customerName;
  }

  getNotes() {
    return this.notes;
  }

  getIsPaid() {
    return this.isPaid;
  }

  getIsActive() {
    return this.isActive;
  }

  getToken() {
    return this.token;
  }

  getAmount() {
    return this.amount;
  }

  getCreatedAt() {
    return this.createdAt;
  }

  getUpdatedAt() {
    return this.updatedAt;
  }

  getCreatedByUserId() {
    return this.createdByUserId;
  }

  getTransactions() {
    return this.transactions;
  }

  getCreatedByUser() {
    return this.createdByUser;
  }

  getEmail() {
    return this.email;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

  isProcessingPaidOff() {
    let remainingBalance = this.amount;
    this.transactions?.forEach((transaction) => {
      if (transaction.getStatus() === 'COMPLETED' || transaction.getStatus() === 'PROCESSING') {
        remainingBalance -= transaction.getInvoiceAmount()
      }
    });
    return remainingBalance <= 0;
  }
}
