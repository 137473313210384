import React, { Component } from 'react';
import { Container, Typography, TextField, InputAdornment } from '@mui/material';
import { Redirect } from 'react-router-dom';
import { Button } from '@lexcelon/react-util';

import { createInvoice } from '../../../../api';

// Alerts
import { setSuccess, setError } from '../../../../alerts';

class NewInvoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      redirectToInvoiceId: null,
      customerName: '',
      email: '',
      amount: '',
      invoiceNumber: '',
      dueDate: '',
      notes: ''
    };
  }

  onSubmit = (e) => {
    e.preventDefault();

    // Start loading
    this.setState({ isLoading: true });

    const {
      customerName,
      email,
      amount,
      invoiceNumber,
      dueDate,
      notes
    } = this.state;

    createInvoice({
      customerName,
      email,
      amount,
      invoiceNumber: invoiceNumber === '' ? null : invoiceNumber,
      dueDate: dueDate === '' ? null : dueDate,
      notes: notes === '' ? null : notes
    }).then(invoice => {
      setSuccess('Successfully created invoice!');
      this.setState({ isLoading: false, redirectToInvoiceId: invoice.getId() });
    }).catch(error => {
      setError(error ?? 'Error: Unable to create invoice. Please try again.');
      this.setState({ isLoading: false });
    });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return this.state.redirectToInvoiceId != null ? <Redirect to={`/admin/invoices/${this.state.redirectToInvoiceId}`} /> : (
      <Container>
        <Typography variant='h1' style={{ margin: '1em', textAlign: 'center' }}>Create Invoice</Typography>

        <form onSubmit={this.onSubmit}>
          <>
            {/* Customer Name */}
            <TextField
              required
              name='customerName'
              label='Customer Name'
              value={this.state.customerName}
              onChange={this.onChange}
              variant='filled'
              disabled={this.state.isLoading}
              style={{ width: '100%', marginBottom: '0.5em' }}
            />

            {/* Customer Email */}
            <TextField
              required
              name='email'
              label='Customer Email'
              type='email'
              value={this.state.email}
              onChange={this.onChange}
              variant='filled'
              disabled={this.state.isLoading}
              style={{ width: '100%', marginBottom: '0.5em' }}
            />

            {/* Amount */}
            <TextField
              required
              name='amount'
              label='Amount'
              value={this.state.amount}
              onChange={this.onChange}
              variant='filled'
              disabled={this.state.isLoading}
              style={{ width: '100%', marginBottom: '0.5em' }}
              type='number'
              step='0.01'
              min='0'
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }}
            />

            {/* Invoice Number */}
            <TextField
              name='invoiceNumber'
              label='Invoice Number'
              value={this.state.invoiceNumber}
              onChange={this.onChange}
              variant='filled'
              disabled={this.state.isLoading}
              style={{ width: '100%', marginBottom: '0.5em' }}
              type='number'
            />

            {/* Due Date 
            <TextField
              name='dueDate'
              label='Due Date'
              value={this.state.dueDate}
              onChange={this.onChange}
              variant='filled'
              disabled={this.state.isLoading}
              style={{ width: '100%', marginBottom: '0.5em' }}
            />*/}
            
            {/* Notes */}
            <TextField
              multiline
              rows={4}
              name='notes'
              label='Notes (Appears on Invoice)'
              value={this.state.notes}
              onChange={this.onChange}
              variant='filled'
              disabled={this.state.isLoading}
              style={{ width: '100%', marginBottom: '0.5em' }}
            />
          </>

          {/* Submit Button */}
          <Button type='submit' isLoading={this.state.isLoading} style={{ marginTop: '1em', width: '100%' }}>
            Submit
          </Button>
        </form>
      </Container>
    );
  }
}

export default NewInvoice;