import { DateTime } from 'luxon';

import { Invoice } from '.';

export default class Transaction {
  static thaw(transactionObj) {
    if (transactionObj == null) return null;
    const transaction = new Transaction();
    if (transactionObj.id != null) {
      transaction.setId(transactionObj.id);
    }
    if (transactionObj.paymentType != null) {
      transaction.setPaymentType(transactionObj.paymentType);
    }
    if (transactionObj.status != null) {
      transaction.setStatus(transactionObj.status);
    }
    if (transactionObj.stripePaymentIntentId != null) {
      transaction.setStripePaymentIntentId(transactionObj.stripePaymentIntentId);
    }
    if (transactionObj.timestamp != null) {
      transaction.setTimestamp(DateTime?.fromISO(transactionObj.timestamp) ?? null);
    }
    if (transactionObj.invoiceAmount != null) {
      transaction.setInvoiceAmount(transactionObj.invoiceAmount);
    }
    if (transactionObj.fees != null) {
      transaction.setFees(transactionObj.fees);
    }
    if (transactionObj.totalPaid != null) {
      transaction.setTotalPaid(transactionObj.totalPaid);
    }
    if (transactionObj.createdAt != null) {
      transaction.setCreatedAt(DateTime?.fromISO(transactionObj.createdAt) ?? null);
    }
    if (transactionObj.updatedAt != null) {
      transaction.setUpdatedAt(DateTime?.fromISO(transactionObj.updatedAt) ?? null);
    }
    if (transactionObj.invoiceId != null) {
      transaction.setInvoiceId(transactionObj.invoiceId);
    }
    if (transactionObj.invoice != null) {
      transaction.setInvoice(Invoice.thaw(transactionObj.invoice));
    }
    return transaction;
  }

  static thawList(transactionObjList) {
    if (transactionObjList == null) {
      return [];
    }
    const transactions = [];
    for (let i = 0; i < transactionObjList.length; i++) {
      transactions.push(Transaction.thaw(transactionObjList[i]));
    }
    return transactions;
  }

  static freeze(transaction) {
    if (transaction == null) {
      return null;
    }
    const transactionObj = {};
    if (transaction.getId() != null) {
      transactionObj.id = transaction.getId();
    }
    if (transaction.getPaymentType() != null) {
      transactionObj.paymentType = transaction.getPaymentType();
    }
    if (transaction.getStatus() != null) {
      transactionObj.status = transaction.getStatus();
    }
    if (transaction.getStripePaymentIntentId() != null) {
      transactionObj.stripePaymentIntentId = transaction.getStripePaymentIntentId();
    }
    if (transaction.getTimestamp() != null) {
      transactionObj.timestamp = transaction.getTimestamp()?.toISO() ?? null;
    }
    if (transaction.getInvoiceAmount() != null) {
      transactionObj.invoiceAmount = transaction.getInvoiceAmount();
    }
    if (transaction.getFees() != null) {
      transactionObj.fees = transaction.getFees();
    }
    if (transaction.getTotalPaid() != null) {
      transactionObj.totalPaid = transaction.getTotalPaid();
    }
    if (transaction.getCreatedAt() != null) {
      transactionObj.createdAt = transaction.getCreatedAt()?.toISO() ?? null;
    }
    if (transaction.getUpdatedAt() != null) {
      transactionObj.updatedAt = transaction.getUpdatedAt()?.toISO() ?? null;
    }
    if (transaction.getInvoiceId() != null) {
      transactionObj.invoiceId = transaction.getInvoiceId();
    }
    if (transaction.getInvoice() != null) {
      transactionObj.invoice = Invoice.freeze(transaction.getInvoice());
    }
    return transactionObj;
  }

  static freezeList(transactions) {
    if (transactions == null) {
      return [];
    }
    const transactionObjList = [];
    for (let i = 0; i < transactions.length; i++) {
      transactionObjList.push(Transaction.freeze(transactions[i]));
    }
    return transactionObjList;
  }

  constructor() {
    this.id = null;
    this.paymentType = null;
    this.status = null;
    this.stripePaymentIntentId = null;
    this.timestamp = null;
    this.invoiceAmount = null;
    this.fees = null;
    this.totalPaid = null;
    this.createdAt = null;
    this.updatedAt = null;
    this.invoiceId = null;
    this.invoice = null;
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setId(id) {
    this.id = id;
  }

  setPaymentType(paymentType) {
    this.paymentType = paymentType;
  }

  setStatus(status) {
    this.status = status;
  }

  setStripePaymentIntentId(stripePaymentIntentId) {
    this.stripePaymentIntentId = stripePaymentIntentId;
  }

  setTimestamp(timestamp) {
    this.timestamp = timestamp;
  }

  setInvoiceAmount(invoiceAmount) {
    this.invoiceAmount = invoiceAmount;
  }

  setFees(fees) {
    this.fees = fees;
  }

  setTotalPaid(totalPaid) {
    this.totalPaid = totalPaid;
  }

  setCreatedAt(createdAt) {
    this.createdAt = createdAt;
  }

  setUpdatedAt(updatedAt) {
    this.updatedAt = updatedAt;
  }

  setInvoiceId(invoiceId) {
    this.invoiceId = invoiceId;
  }

  setInvoice(invoice) {
    this.invoice = invoice;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getId() {
    return this.id;
  }

  getPaymentType() {
    return this.paymentType;
  }

  getStatus() {
    return this.status;
  }

  getStripePaymentIntentId() {
    return this.stripePaymentIntentId;
  }

  getTimestamp() {
    return this.timestamp;
  }

  getInvoiceAmount() {
    return this.invoiceAmount;
  }

  getFees() {
    return this.fees;
  }

  getTotalPaid() {
    return this.totalPaid;
  }

  getCreatedAt() {
    return this.createdAt;
  }

  getUpdatedAt() {
    return this.updatedAt;
  }

  getInvoiceId() {
    return this.invoiceId;
  }

  getInvoice() {
    return this.invoice;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */
}