import React, { Component } from 'react';
import { Container, Typography } from '@mui/material';

class NotFound extends Component {
  render() {
    return (
      <Container>
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '0.5em' }}>Uh oh!</Typography>
        <Typography variant='body1' style={{ textAlign: 'center' }}>We couldn't find the page you were looking for.</Typography>
      </Container>
    );
  }
}

export default NotFound;