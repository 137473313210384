export const IS_LOADING = 'IS_LOADING';
export const IS_NOT_LOADING = 'IS_NOT_LOADING';
export const AUTHENTICATED = 'AUTHENTICATED';
export const NOT_AUTHENTICATED = 'NOT_AUTHENTICATED';

const initialState = {
  isLoading: true,
  isAuthenticated: false,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case IS_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case IS_NOT_LOADING:
      return {
        ...state,
        isLoading: false
      };
    case AUTHENTICATED:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
      };
    case NOT_AUTHENTICATED:
      return {
        isLoading: false,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

export default auth;
