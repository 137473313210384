import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionSummary, AccordionDetails, Box, Card, Container, FormControl, FormControlLabel, Grid, InputAdornment, Radio, RadioGroup, TextField, ToggleButtonGroup, ToggleButton, Typography, CircularProgress } from '@mui/material';
import { withRouter, Redirect } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { stripePromise } from '../../../App';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { GREEN, GREY } from '../../../constants';

// API
import { getInvoiceByToken, getInvoiceDetailsByToken } from '../../../api';

// Alerts
import { setError, clearErrors, setSuccess } from '../../../alerts';
import { Button, LineItem } from '@lexcelon/react-util';
import PurchaseForm from './PurchaseForm';

class PublicInvoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoice: null,
      selectedTabIndex: 0,
      paymentMethod: 'ach',
      paymentType: 'full',
      partialAmountText: '0.00',
      partialAmount: null,
      invoiceDetails: null,
      isLoading: false,
      redirectTo: null,
      isLoadingInvoice: true
    };
  }

  componentDidMount() {
    this.loadInvoice();
  }

  loadInvoice() {
    this.setState({ isLoadingInvoice: true, paymentMethod: 'ach', paymentType: 'full', partialAmountText: '0.00', partialAmount: null, invoiceDetails: null });
    getInvoiceByToken(this.props.match.params.token).then((invoice) => {
      this.setState({ invoice, isLoadingInvoice: false });

      if (!invoice.isProcessingPaidOff()) {
        const {
          paymentMethod,
          paymentType
        } = this.state;
        getInvoiceDetailsByToken({ token: this.props.match.params.token, paymentMethod, paymentType }).then((invoiceDetails) => {
          this.setState({ invoiceDetails });
        }).catch(error => {
          setError(error ?? 'Error: Could not retrieve invoice details.');
        });
      }
    }).catch(error => {
      setError(error ?? 'Error: Could not retrieve invoice.');
      this.setState({ isLoadingInvoice: false, redirectTo: '/404' });
    });
  }

  savePartialAmount = () => {
    // Validate this.state.partialAmountText to make sure it is a valid dollar amount
    const partialAmount = parseInt(this.state.partialAmountText);
    if (isNaN(partialAmount)) {
      setError('Error: Invalid partial amount.');
      return;
    }

    // Make sure the partial amount is not greater than the total invoice amount or less than or equal to 0
    if (partialAmount > this.state.invoice?.getAmount() || partialAmount <= 0) {
      setError('Error: Partial amount must be greater than 0 and less than or equal to the total invoice amount.');
      return;
    }

    // Make sure the partial amount does not exceed the remaining balance
    let remainingBalance = this.state.invoice?.getAmount();
    this.state.invoice.getTransactions().forEach(transaction => {
      if (transaction.getStatus() !== 'FAILED' && transaction.getStatus() !== 'CANCELED') remainingBalance -= transaction.getInvoiceAmount();
    });
    if (partialAmount > remainingBalance) {
      setError('Error: Partial amount must be less than or equal to the remaining balance.');
      return;
    }

    clearErrors();

    // Regenerate invoice details
    getInvoiceDetailsByToken({ token: this.props.match.params.token, paymentMethod: this.state.paymentMethod, paymentType: 'partial', amount: partialAmount }).then((invoiceDetails) => {
      this.setState({ invoiceDetails, partialAmount });
    }).catch(error => {
      setError(error ?? 'Error: Could not retrieve invoice details.');
    });
  }

  onPaymentMethodChange = (_, paymentMethod) => {
    this.setState({ paymentMethod, invoiceDetails: null });

    if (this.state.paymentType === 'full' || this.state.partialAmount != null) {
      // Regenerate invoice details
      let amountObj = {};
      if (this.state.paymentType === 'partial') amountObj = { amount: this.state.partialAmount };
      getInvoiceDetailsByToken({ token: this.props.match.params.token, paymentMethod, paymentType: this.state.paymentType, ...amountObj }).then((invoiceDetails) => {
        this.setState({ invoiceDetails });
      }).catch(error => {
        setError(error ?? 'Error: Could not retrieve invoice details.');
      });
    }
  }

  onPaymentTypeChange = (e) => {
    this.setState({ paymentType: e.target.value, partialAmountText: '', partialAmount: null, invoiceDetails: null });

    // Regenerate invoice details
    if (e.target.value === 'full') {
      getInvoiceDetailsByToken({ token: this.props.match.params.token, paymentMethod: this.state.paymentMethod, paymentType: 'full' }).then((invoiceDetails) => {
        this.setState({ invoiceDetails });
      }).catch(error => {
        setError(error ?? 'Error: Could not retrieve invoice details.');
      });
    }
  }

  submitPayment = (e, stripe, elements) => {
    e.preventDefault();

    // Make sure to disable the form until Stripe.js has loaded
    if (!stripe || !elements) return;
    const paymentElement = elements.getElement('payment');
    paymentElement.update({ readOnly: true });
    this.setState({ isLoading: true });

    stripe.confirmPayment({
      elements,
      redirect: 'if_required',
      confirmParams: {
        payment_method_data: {
          billing_details: {
            email: this.state.invoice.getEmail()
          }
        }
      }
    }).then((result) => {
      this.setState({ isLoading: false });
      if (result.error) {
        setError(result.error.message ?? 'Error: Unable to submit payment.');
        paymentElement.update({ readOnly: false });
      }
      else {
        let redirectUrl = `/invoices/${this.props.match.params.token}/success`;
        switch (result?.paymentIntent?.status) {
          case 'succeeded':
            setSuccess('Payment succeeded! Thank you!');
            // Redirect to confirmation page
            this.setState({ redirectTo: encodeURI(redirectUrl + '?title=Success!&message=We received your payment. Thank you!') });
            break;
          case 'processing':
            setSuccess('Your payment is processing. Please check back soon if the payment went through. Thank you!');
            // Redirect to confirmation page
            this.setState({ redirectTo: encodeURI(redirectUrl + '?title=Success!&message=Your payment is processing.') });
            break;
          case 'requires_payment_method':
            setError('Your payment was not successful. Please try again.');
            // Reload the Invoice
            this.loadInvoice();
            break;
          case 'requires_action':
            setSuccess('Your payment requires additional action. Please follow the directions in the email sent to you. Thank you!');
            // Redirect to confirmation page
            this.setState({ redirectTo: encodeURI(redirectUrl + '?title=Success!&message=Your payment requires additional action. Please follow the directions in the email sent to you.') });
            break;
          default:
            setError('Something went wrong. Please try again.');
            // Reload the Invoice
            this.loadInvoice();
            break;
        }
      }
    }).catch(error => {
      this.setState({ isLoading: false });
      paymentElement.update({ readOnly: false });
      setError(error ?? 'Error: Unable to submit payment.');
    });
  }

  render() {
    return this.state.redirectTo != null ? <Redirect to={this.state.redirectTo} /> : (
      <Container>
        {this.state.isLoadingInvoice ? (
          <CircularProgress />
        ) : (
          <>
            {/* Customer Name */}
            <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '0.2em' }}>{this.state.invoice?.getCustomerName()} Invoice</Typography>
            
            {/* Invoice Status */}
            <Typography variant='subtitle1' style={{ textAlign: 'center', fontWeight: 'bold', fontStyle: 'italic', color: this.state.invoice?.getIsPaid() ? GREEN.main : (this.state.invoice?.isProcessingPaidOff() ? GREY.light : 'red')  }}>
              {this.state.invoice?.getIsPaid() ? 'PAID' : (this.state.invoice?.isProcessingPaidOff() ? 'PROCESSING' : 'UNPAID')}
            </Typography>

            {/* Total Invoice Amount */}
            <LineItem
              value={this.state.invoice?.getAmount()?.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}
              description='Total Invoice Amount'
            />

            {/* Invoice Number */}
            {this.state.invoice?.getInvoiceNumber() != null &&
            <LineItem
              value={this.state.invoice?.getInvoiceNumber()}
              description='Invoice Number'
            />}

            {this.state.invoice != null && this.state.invoice.getTransactions()?.length > 0 &&
            <Accordion elevation={3} style={{ marginBottom: '1em' }} defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h2'>Transaction History</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {this.state.invoice?.getTransactions().map((transaction, index) => (
                  <Box key={index} style={{ backgroundColor: transaction.getStatus() === 'COMPLETED' ? GREEN.main : (transaction.getStatus() === 'PROCESSING' ? GREY.light : 'red'), padding: '1em', borderRadius: '10px', color: 'white', marginBottom: index < this.state.invoice.getTransactions().length - 1 ? '10px' : '0px' }}>
                    <Grid container>
                      <Grid item xs={12} sm={8}>
                        <Typography variant='h3' sx={{ textAlign: { xs: 'center', sm: 'left' } }}>{transaction.getTimestamp().toLocaleString({ weekday: 'short', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit', timeZoneName: 'short' })}</Typography>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Typography variant='h3' sx={{ textAlign: { xs: 'center', sm: 'right' }, fontWeight: 'bold' }}>{transaction.getStatus()}</Typography>
                      </Grid>
                    </Grid>
                    <LineItem
                      value={transaction.getInvoiceAmount()?.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                      description='Invoice Amount'
                    />
                    <LineItem
                      value={transaction.getFees()?.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                      description='Fees'
                    />
                    <LineItem
                      value={transaction.getTotalPaid()?.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                      description='Total Paid'
                    />
                    <LineItem
                      value={transaction.getPaymentType()}
                      description='Payment Type'
                    />
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>}

            {/* Payment Methods */}
            {!(this.state.invoice?.isProcessingPaidOff() || this.state.invoice?.getIsPaid()) && (
              <Card style={{ padding: '2em', display: 'flex', flexDirection: 'column', alignItems: 'center' }} elevation={3}>
                <Typography variant='h2' style={{ textAlign: 'center', marginBottom: '1em' }}>Pay Invoice</Typography>
                <ToggleButtonGroup
                  color='primary'
                  value={this.state.paymentMethod}
                  exclusive
                  onChange={this.onPaymentMethodChange}
                  style={{ marginBottom: '1em' }}
                  disabled={this.state.isLoading}
                >
                  <ToggleButton value='ach'>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Typography variant='h3'>ACH Payment</Typography>
                      <Typography variant='subtitle2'>No Fees</Typography>
                    </div>
                  </ToggleButton>
                  <ToggleButton value='card'>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Typography variant='h3'>Credit/Debit Card</Typography>
                      <Typography variant='subtitle2'>~3.5% Fee</Typography>
                    </div>
                  </ToggleButton>
                </ToggleButtonGroup>

                <div style={{ width: '100%', marginBottom: '2em' }}>
                  <FormControl>
                    <RadioGroup value={this.state.paymentType} onChange={this.onPaymentTypeChange}>
                      <FormControlLabel value='full' control={<Radio disabled={this.state.isLoading} />} label='Pay Full Remaining Balance' />
                      <FormControlLabel value='partial' control={<Radio disabled={this.state.isLoading} />} label='Pay Partial Balance' />
                    </RadioGroup>
                  </FormControl>

                  {this.state.paymentType === 'partial' && this.state.partialAmount == null && (
                    <div style={{ marginTop: '1em', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <TextField
                        required
                        name='partialAmountText'
                        label='Partial Payment Amount'
                        value={this.state.partialAmountText}
                        onChange={(e) => {
                          let val = e.target.value;
                          val = val.replace(/[^0-9]/g, '');
                          this.setState({ [e.target.name]: val });
                        }}
                        variant='filled'
                        disabled={this.state.isLoading}
                        style={{ width: '100%', marginBottom: '0.5em' }}
                        type='number'
                        step='0.01'
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                      />
                      <Button style={{ width: '250px', marginLeft: '10px' }} onClick={this.savePartialAmount} disabled={this.state.isLoading}>Save Amount</Button>
                    </div>
                  )}
                </div>

                {/* Invoice Totals */}
                {this.state.invoiceDetails != null &&
                <Elements key={this.state.invoiceDetails.clientSecret} stripe={stripePromise} options={{ clientSecret: this.state.invoiceDetails.clientSecret }}>
                  <PurchaseForm
                    amount={this.state.invoiceDetails.amount}
                    fees={this.state.invoiceDetails.fees}
                    total={this.state.invoiceDetails.total}
                    isLoading={this.state.isLoading}
                    submitPayment={this.submitPayment}
                  />
                </Elements>}
              </Card>
            )}
          </>
        )}
      </Container>
    );
  }
}

PublicInvoice.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(PublicInvoice);