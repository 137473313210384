/*
 * Copyright © 2021 Lexcelon LLC <info@lexcelon.com>
 * Licensed for non-distributable use
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@mui/material';

// Redux
import { connect } from 'react-redux';
import { removeAlert } from '../redux/actions/alertActions';

class AlertList extends Component {
  render() {
    return (
      <>
        {this.props.alerts.map((alert, index) => (
          <Alert key={index} severity={alert.severity} onClose={() => this.props.removeAlert(alert.id)}>
            {alert.msg}
          </Alert>
        ))}
      </>
    );
  }
}

AlertList.propTypes = {
  alerts: PropTypes.array.isRequired,
  removeAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  alerts: state.alerts
});

const mapDispatchToProps = dispatch => ({
  removeAlert: id => dispatch(removeAlert(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertList);
