import { createTheme } from '@mui/material/styles';

// Constants
import { GREEN, GREY } from './constants';

// https://material-ui.com/customization/palette/
export default createTheme({
  palette: {
    primary: {
      light: GREEN.light,
      main: GREEN.main,
      dark: GREEN.dark,
      contrastText: GREEN.text
    },
    secondary: {
      light: GREY.light,
      main: GREY.main,
      dark: GREY.dark,
      contrastText: GREY.text
    }
  },
  typography: {
    h1: {
      fontSize: '70px',
      fontWeight: '300'
    },
    h2: {
      fontSize: '40px'
    },
    h3: {
      fontSize: '25px'
    },
    body1: {
      fontSize: '19px'
    },
    body2: {
      fontSize: '16px'
    },
    subtitle1: {
      fontSize: '21px',
      lineHeight: '1.25em'
    },
    button: {
      fontSize: '18px',
      textTransform: 'none'
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          boxSizing: 'border-box',
          margin: 0
        }
      }
    }
  }
});
