import React from 'react';
import PropTypes from 'prop-types';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button, LineItem } from '@lexcelon/react-util';

const PurchaseForm = ({ amount, fees, total, isLoading, submitPayment }) => {
  const stripe = useStripe();
  const elements = useElements();
  
  return (
    <form onSubmit={(e) => submitPayment(e, stripe, elements)} style={{ width: '100%' }}>
      <div style={{ width: '100%', marginBottom: '2em' }}>
        <LineItem
          value={amount?.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
          description='Invoice Payment Amount'
        />

        <LineItem
          value={fees?.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
          description='Processing Fees'
        />

        <LineItem
          value={total?.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
          description='You Pay'
        />

      </div>

      <div style={{ width: '100%' }}>
        <PaymentElement options={{ fields: { billingDetails: { email: 'never' } } }} />
      </div>
      <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Button style={{ minWidth: '50%', marginTop: '2em' }} type='submit' isLoading={isLoading} disabled={!stripe}>Submit Payment</Button>
      </div>
    </form>
  );
}

PurchaseForm.propTypes = {
  amount: PropTypes.number,
  fees: PropTypes.number,
  total: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
  submitPayment: PropTypes.func.isRequired
};

export default PurchaseForm;