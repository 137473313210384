import { DateTime } from 'luxon';

export default class User {
  static thaw(userObj) {
    if (userObj == null) return null;
    const user = new User();
    if (userObj.id != null) {
      user.setId(userObj.id);
    }
    if (userObj.firstName != null) {
      user.setFirstName(userObj.firstName);
    }
    if (userObj.lastName != null) {
      user.setLastName(userObj.lastName);
    }
    if (userObj.email != null) {
      user.setEmail(userObj.email);
    }
    if (userObj.hash != null) {
      user.setHash(userObj.hash);
    }
    if (userObj.isSuspended != null) {
      user.setIsSuspended(userObj.isSuspended == null ? null : (userObj.isSuspended ? true : false));
    }
    if (userObj.createdAt != null) {
      user.setCreatedAt(DateTime?.fromISO(userObj.createdAt) ?? null);
    }
    if (userObj.updatedAt != null) {
      user.setUpdatedAt(DateTime?.fromISO(userObj.updatedAt) ?? null);
    }
    return user;
  }

  static thawList(userObjList) {
    if (userObjList == null) {
      return [];
    }
    const users = [];
    for (let i = 0; i < userObjList.length; i++) {
      users.push(User.thaw(userObjList[i]));
    }
    return users;
  }

  static freeze(user) {
    if (user == null) {
      return null;
    }
    const userObj = {};
    if (user.getId() != null) {
      userObj.id = user.getId();
    }
    if (user.getFirstName() != null) {
      userObj.firstName = user.getFirstName();
    }
    if (user.getLastName() != null) {
      userObj.lastName = user.getLastName();
    }
    if (user.getEmail() != null) {
      userObj.email = user.getEmail();
    }
    if (user.getHash() != null) {
      userObj.hash = user.getHash();
    }
    if (user.getIsSuspended() != null) {
      userObj.isSuspended = user.getIsSuspended() == null ? null : (user.getIsSuspended() ? 1 : 0);
    }
    if (user.getCreatedAt() != null) {
      userObj.createdAt = user.getCreatedAt()?.toISO() ?? null;
    }
    if (user.getUpdatedAt() != null) {
      userObj.updatedAt = user.getUpdatedAt()?.toISO() ?? null;
    }
    return userObj;
  }

  static freezeList(users) {
    if (users == null) {
      return [];
    }
    const userObjList = [];
    for (let i = 0; i < users.length; i++) {
      userObjList.push(User.freeze(users[i]));
    }
    return userObjList;
  }

  constructor() {
    this.id = null;
    this.firstName = null;
    this.lastName = null;
    this.email = null;
    this.hash = null;
    this.isSuspended = null;
    this.createdAt = null;
    this.updatedAt = null;
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setId(id) {
    this.id = id;
  }

  setFirstName(firstName) {
    this.firstName = firstName;
  }

  setLastName(lastName) {
    this.lastName = lastName;
  }

  setEmail(email) {
    this.email = email;
  }

  setHash(hash) {
    this.hash = hash;
  }

  setIsSuspended(isSuspended) {
    this.isSuspended = isSuspended;
  }

  setCreatedAt(createdAt) {
    this.createdAt = createdAt;
  }

  setUpdatedAt(updatedAt) {
    this.updatedAt = updatedAt;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getId() {
    return this.id;
  }

  getFirstName() {
    return this.firstName;
  }

  getLastName() {
    return this.lastName;
  }

  getEmail() {
    return this.email;
  }

  getHash() {
    return this.hash;
  }

  getIsSuspended() {
    return this.isSuspended;
  }

  getCreatedAt() {
    return this.createdAt;
  }

  getUpdatedAt() {
    return this.updatedAt;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

  getFullName() {
    return this.firstName != null && this.lastName != null ? this.firstName + ' ' + this.lastName : null;
  }
}