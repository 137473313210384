/*
 * Copyright © 2021 Lexcelon LLC <info@lexcelon.com>
 * Licensed for non-distributable use
 */

export const CREATE_CONFIRMATION = 'CREATE_CONFIRMATION';
export const REMOVE_CONFIRMATION = 'REMOVE_CONFIRMATION';
export const START_CONFIRMATION_LOADING = 'START_CONFIRMATION_LOADING';
export const STOP_CONFIRMATION_LOADING = 'STOP_CONFIRMATION_LOADING';

export const createConfirmation = ({ title, onConfirm, onClose = null, body = null, danger = false }) => ({
  type: CREATE_CONFIRMATION,
  onClose,
  onConfirm,
  title,
  body,
  danger
});

export const removeConfirmation = () => ({
  type: REMOVE_CONFIRMATION
});

export const startConfirmationLoading = () => ({
  type: START_CONFIRMATION_LOADING
});

export const stopConfirmationLoading = () => ({
  type: STOP_CONFIRMATION_LOADING
});
