import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import AuthRedirect from './AuthRedirect';
import HomeRedirect from './HomeRedirect';

// Pages
import Login from '../pages/auth/login';

import PublicInvoiceSuccess from '../pages/invoices/[token]/success';
import PublicInvoice from '../pages/invoices/[token]';

import Invoices from '../pages/admin/invoices';
import NewInvoice from '../pages/admin/invoices/create';
import Invoice from '../pages/admin/invoices/[id]';

import NotFound from '../pages/NotFound';


class RouteAndWrapper extends Component {
  render() {
    return (
      <Route path={this.props.path} exact={this.props.exact}>
        {AuthRedirect(this.props.location.pathname, this.props.authProps) ?? this.props.children}
      </Route>
    );
  }
}

RouteAndWrapper.propTypes = {
  children: PropTypes.any.isRequired,
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  location: PropTypes.object.isRequired,
  authProps: PropTypes.exact({
    isLoading: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired
  }).isRequired
};

const routeAndWrapperMapStateToProps = state => ({
  authProps: state.auth
});

const ConnectedRouteAndWrapper = connect(routeAndWrapperMapStateToProps, null)(withRouter(RouteAndWrapper));

export default class Navigation extends Component {
  render() {
    return (
      <div style={{ paddingBottom: '120px' }}>
        <Switch>
          <ConnectedRouteAndWrapper path='/404'>
            <NotFound />
          </ConnectedRouteAndWrapper>

          <ConnectedRouteAndWrapper path='/auth/login'>
            <Login />
          </ConnectedRouteAndWrapper>

          <ConnectedRouteAndWrapper path='/invoices/:token/success'>
            <PublicInvoiceSuccess />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/invoices/:token'>
            <PublicInvoice />
          </ConnectedRouteAndWrapper>

          <ConnectedRouteAndWrapper path='/admin/invoices/create'>
            <NewInvoice />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/invoices/:id'>
            <Invoice />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/invoices'>
            <Invoices />
          </ConnectedRouteAndWrapper>

          <ConnectedRouteAndWrapper path='/' exact>
            <HomeRedirect />
          </ConnectedRouteAndWrapper>


          <ConnectedRouteAndWrapper path='/'>
            <Redirect to='/404' />
          </ConnectedRouteAndWrapper>
        </Switch>
      </div>
    );
  }
}
