import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Button } from '@lexcelon/react-util';
import qs from 'qs';
import { Container, Typography } from '@mui/material';

class PublicInvoiceSuccess extends React.Component {
  constructor(props) {
    super(props);

    this.query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
  }

  render() {
    return (
      <Container style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Typography variant='h1' style={{ marginTop: '1em', marginBottom: '1em' }}>{this.query?.title}</Typography>
        <Typography variant='body1' style={{ marginBottom: '1em' }}>{this.query?.message}</Typography>
        <Button component={Link} to={`/invoices/${this.props.match.params.token}`}>Back to Invoice</Button>
      </Container>
    );
  }
}

export default withRouter(PublicInvoiceSuccess);