import store from '../redux/store';
import { createConfirmation, removeConfirmation, startConfirmationLoading, stopConfirmationLoading } from '../redux/actions/confirmActions';

//
// props is expected to be an object containing the following:
// onClose (function): [Optional] What happens on cancellation of the modal
// onConfirm (function): What happens on successful confirmation
// title (string): [Optional] Title text of the confirmation modal
// body (string): [Optional] Body text of the confirmation modal
// danger (boolean): [Optional] Whether or not the user is confirming a dangerous action
//
export function confirm(props) {
  store?.dispatch(createConfirmation(props));
}

export function closeConfirm() {
  store?.dispatch(removeConfirmation());
}

export function startConfirmLoading() {
  store?.dispatch(startConfirmationLoading());
}

export function stopConfirmLoading() {
  store?.dispatch(stopConfirmationLoading());
}
