import axios from 'axios';
import store from '../redux/store';
import { clearCookies } from '../redux/actions/authActions';

import { Invoice } from '../lib';

// Create your axios with credentials object
const BASE_URL = process.env.REACT_APP_SERVER_URL || 'http://localhost:8000/api';
export const WEBSOCKET_BASE_URL = process.env.REACT_APP_SERVER_WS_URL || 'ws://localhost:8000';
// TODO: make these env vars?
const axiosWithCredentials = axios.create({
  withCredentials: true,
  baseURL: BASE_URL
});

// Intercept the success and errors, if needed
axiosWithCredentials.interceptors?.response?.use(response => {
  return Promise.resolve(response?.data != null ? (response.data === '' ? null : response.data) : response);
}, error => {
  if (error?.response?.status === 401) {
    clearCookies(store.dispatch);
  }
  return Promise.reject(error?.response?.data?.message || error?.response?.data || error);
});

/*
 * Account Routes
*/

export function logout() {
    return new Promise(function (resolve, reject) {
      axiosWithCredentials.post('/account/logout').then(() => {
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  }

/*
 * Auth Routes
*/

export function login({ email, password }) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post('/auth/login', { email, password }).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function forgotPasswordRequest({ email }) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post('/auth/forgot-password', { email }).then((message) => {
      resolve(message);
    }).catch(error => {
      reject(error);
    });
  });
}

export function forgotPasswordConfirm({ token, password }) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post('/auth/forgot-password/confirm', { token, password }).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

/*
 * Admin Invoice Routes
*/
export function createInvoice({ customerName, email, amount, invoiceNumber, dueDate, notes }) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post('/invoice', { customerName, email, amount, invoiceNumber, dueDate, notes }).then((invoice) => {
      resolve(Invoice.thaw(invoice));
    }).catch(error => {
      reject(error);
    });
  });
}

export function getInvoice(id) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get(`/invoice/${id}`).then((invoice) => {
      resolve(Invoice.thaw(invoice));
    }).catch(error => {
      reject(error);
    });
  });
}

export function listInvoices({ options = {} }) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get(`/invoice`, { params: { options: encodedOptions } }).then(({ results, totalCount }) => {
      resolve({ results: Invoice.thawList(results), totalCount });
    }).catch(error => {
      reject(error);
    });
  });
}

export function updateInvoice(id, { isActive }) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.patch(`/invoice/${id}`, { isActive }).then((invoice) => {
      resolve(Invoice.thaw(invoice));
    }).catch(error => {
      reject(error);
    });
  });
}

/*
 * Public Invoice Routes
 */

export function getInvoiceByToken(token) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get(`/public/invoice/${token}`).then((invoice) => {
      resolve(Invoice.thaw(invoice));
    }).catch(error => {
      reject(error);
    });
  });
}

export function getInvoiceDetailsByToken({ token, paymentType, paymentMethod, amount }) {
  // amount must be passed in if paymentType == 'partial'
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get(`/public/invoice/${token}/details`, { params: { paymentType, paymentMethod, amount } }).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}
