import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { Button, MaterialTable } from '@lexcelon/react-util';
import { setError } from '../../../alerts';

// Icons
import { Visibility as VisibilityIcon, Add as AddIcon } from '@mui/icons-material/';

import { listInvoices } from '../../../api';

const COLUMNS = [
  {
    title: 'Customer Name',
    remoteField: 'customerName',
    value: rowData => rowData.getCustomerName(),
    search: 'column'
  },
  {
    title: 'Amount',
    remoteField: 'amount',
    value: rowData => rowData.getAmount()?.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    }),
    search: 'range'
  },
  {
    title: 'Invoice Number',
    remoteField: 'invoiceNumber',
    value: rowData => rowData.getInvoiceNumber(),
    search: 'column'
  },
  {
    title: 'Due Date',
    remoteField: 'dueDate',
    value: rowData => rowData.getDueDate()?.toLocaleString(DateTime.DATETIME_MED),
    search: 'date'
  },
  {
    title: 'Actions',
    value: () => '',
    render: (rowData) => (
      <Link to={`/admin/invoices/${rowData.getId()}`}><VisibilityIcon /></Link>
    )
  }
];

class Invoices extends Component {

  render() {
    return (
        <Container>
            <Typography variant='h1' style={{ textAlign: 'center', margin: '1em' }}>Invoices</Typography>

            <MaterialTable
              title={<Button component={Link} to='/admin/invoices/create'>
                Create Invoice
                <AddIcon style={{ marginLeft: '5px' }} />
              </Button>}
              data={{
                mode: 'remote',
                columns: COLUMNS,
                fetchRemoteData: ({ page, rowsPerPage, where = {}, order = [] }) =>
                  new Promise((resolve, reject) => {
                    let options = {
                      offset: page * rowsPerPage,
                      limit: rowsPerPage,
                      where,
                      order,
                    };
                    listInvoices({ options }).then((res) => {
                      resolve(res);
                    }).catch((error) => {
                      reject(error);
                    });
                  })
              }}
              onError={(error) => {
                setError(error);
              }}
              options={{
                pageSize: 20
              }}
            />
        </Container>
    );
  }
}

Invoices.propTypes = {
};
export default Invoices;