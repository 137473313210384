import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import { Header } from '@lexcelon/react-util';

// Images
import LexcelonLogo from '../assets/images/LexcelonLogo.png';

// Redux
import { connect } from 'react-redux';
import { checkAuthentication, logoutUser } from '../redux/actions/authActions';

const LOGGED_IN_LINKS = [
  {
    slug: '/admin/invoices/create',
    text: 'New Invoice'
  },
  {
    slug: '/admin/invoices',
    text: 'Invoices'
  },
  {
    slug: '/account',
    text: 'My Account'
  }
];

const LOGGED_OUT_LINKS = [
  {
    slug: '/auth/login',
    text: 'Admin Login'
  }
];

class AppBar extends Component {
  componentDidMount() {
    this.props.checkAuthentication(this.props.isAuthenticated);
  }

  render() {
    return (
      <Header
        logo={LexcelonLogo}
        logoDescription='Lexcelon Logo'
        links={this.props.isAuthenticated ? LOGGED_IN_LINKS : LOGGED_OUT_LINKS}
        backgroundColor={'white'}
        textColor={'grey'}
        drawer={this.props.isAuthenticated}
        isLoading={this.props.isLoading}
        onLogout={this.props.isAuthenticated ? this.props.logoutUser : null}
      />
    );
  }
}

AppBar.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  checkAuthentication: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  isAuthenticated: state?.auth?.isAuthenticated,
  isLoading: state?.auth?.isLoading
});

const mapDispatchToProps = dispatch => ({
  checkAuthentication: (isAuthenticated) => checkAuthentication(dispatch, isAuthenticated),
  logoutUser: () => logoutUser(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AppBar);
