// Colors
export const GREEN = {
    light: '#B6EB70',
    main: '#598d36',
    dark: '#456A11',
    text: '#FFFFFF'
  };
  
  export const GREY = {
    light: '#858585',
    main: '#747474',
    dark: '#1F1F1F',
    text: '#FFFFFF'
  };
  