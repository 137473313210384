import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

const HomeRedirect = () => {
  const isAuthenticated = useSelector((state) => state?.auth?.isAuthenticated);
  const isLoading = useSelector((state) => state?.auth?.isLoading);

  if (isLoading) {
    return null;
  }
  else {
    if (isAuthenticated) {
      return <Redirect to="/admin/invoices" />;
    }
    else {
      return <Redirect to="/auth/login" />;
    }
  }
};

export default HomeRedirect;