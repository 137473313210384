import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Typography } from '@mui/material';
import { Button, LineItem } from '@lexcelon/react-util';
import { withRouter } from 'react-router-dom';

// API
import { getInvoice, updateInvoice } from '../../../../api';

// Alerts
import { setError, setSuccess } from '../../../../alerts';
import { confirm, startConfirmLoading, stopConfirmLoading, closeConfirm } from '../../../../alerts/confirm';

class Invoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoice: null
    };
  }

  componentDidMount() {
    getInvoice(this.props.match.params.id).then((invoice) => {
      this.setState({ invoice });
    }).catch(error => {
      setError(error ?? 'Error: Could not retrieve invoice.');
    });
  }

  voidInvoice = () => {
    confirm({
      title: 'Void Invoice',
      body: 'Are you sure you want to void this invoice?',
      danger: true,
      onConfirm: () => {
        startConfirmLoading();
        updateInvoice(this.props.match.params.id, { isActive: false }).then(invoice => {
          stopConfirmLoading();
          closeConfirm();
          setSuccess('Successfully voided invoice!');
          this.setState({ invoice });
        }).catch(error => {
          stopConfirmLoading();
          setError(error ?? 'Error: Unable to void invoice. Please try again.');
        });
      },
      onClose: () => closeConfirm()
    })
  }

  render() {
    return (
      <Container>
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '0.5em' }}>Invoice</Typography>
        {!this.state.invoice?.getIsActive() && <Typography variant='subtitle1' style={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}>VOIDED</Typography>}

        <LineItem
          value={this.state.invoice?.getCustomerName()}
          description='Customer Name'
        />

        <LineItem
          value={this.state.invoice?.getEmail()}
          description='Customer Email'
        />

        <LineItem
          value={this.state.invoice?.getAmount()?.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
          description='Amount'
        />

        <LineItem
          value={this.state.invoice?.getInvoiceNumber()}
          description='Invoice Number'
        />

        <LineItem
          value={this.state.invoice?.getDueDate()}
          description='Due Date'
        />

        <LineItem
          value={this.state.invoice?.getNotes()}
          description='Notes'
        />

        {this.state.invoice?.getIsActive() &&
        <LineItem
          value={
            <a href={`${process.env.NODE_ENV === 'production' ? 'https' : 'http'}://${process.env.NODE_ENV === 'production' ? process.env.REACT_APP_COOKIE_DOMAIN : 'localhost:3000'}/invoices/${this.state.invoice?.getToken()}`}>
              {`${process.env.NODE_ENV === 'production' ? 'https' : 'http'}://${process.env.NODE_ENV === 'production' ? process.env.REACT_APP_COOKIE_DOMAIN : 'localhost:3000'}/invoices/${this.state.invoice?.getToken()}`}
            </a>}
          description='Customer Link'
        />}

        {this.state.invoice?.getIsActive() &&
        <Button danger onClick={this.voidInvoice}>Void Invoice</Button>}
      </Container>
    );
  }
}

Invoice.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(Invoice);