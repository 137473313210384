import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Typography, TextField } from '@mui/material';
import { Button } from '@lexcelon/react-util';
import LexcelonLogo from '../../../assets/images/LexcelonLogo.png';

// Redux
import { connect } from 'react-redux';
import { authenticateUser } from '../../../redux/actions/authActions';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
    };
  }

  onSubmit = (e) => {
    e.preventDefault();

    this.props.authenticateUser(this.state.email, this.state.password);
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (
      <Container>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={LexcelonLogo} style={{ width: '12em', marginTop: '2.5em' }} alt='Lexcelon Logo'></img>
        </div>

        <form onSubmit={this.onSubmit}>
          <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>Admin Login</Typography>

          {/* Email */}
          <TextField
            required
            name='email'
            label='Email'
            type='email'
            onChange={this.onChange}
            disabled={this.props.isLoading}
            variant='filled'
            style={{
              width: '100%',
              marginBottom: '0.5em' }}>
          </TextField>

          {/* Password */}
          <TextField
            required
            name='password'
            label='Password'
            type='password'
            onChange={this.onChange}
            disabled={this.props.isLoading}
            variant='filled'
            style={{
              width: '100%',
              marginBottom: '0.5em' }}>
          </TextField>

          {/* Submit */}
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button type='submit'
              style={{ width: '48%', marginTop: '1em' }} isLoading={this.props.isLoading}>
              Submit
            </Button>
          </div>
          {/*
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '.5em' }}>
            Forgot your password?&nbsp;<Link to='/auth/forgot-password/request'>Reset it now.</Link>
          </div>*/}

        </form>
      </Container>
    );
  }
}

Login.propTypes = {
  isAuthenticated: PropTypes.bool,
  isLoading: PropTypes.bool,
  authenticateUser: PropTypes.func
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  isLoading: state.auth.isLoading
});

const mapDispatchToProps = dispatch => ({
  authenticateUser: (username, password) => authenticateUser(dispatch, username, password)
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);