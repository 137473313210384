import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Navigation from './navigation';
import ScrollToTop from './ScrollToTop';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { loadStripe } from '@stripe/stripe-js';

// Components
import { AlertList, ConfirmationModal } from './components';
import AppBar from './navigation/AppBar';
// import Footer from './navigation/Footer';

// Redux
import { Provider } from 'react-redux';
import store from './redux/store';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
export {
  stripePromise
};

export default class App extends React.Component {
  componentDidMount() {
    document.addEventListener("wheel", function(event){
      if(document.activeElement.type === "number"){
          document.activeElement.blur();
      }
    });
  }

  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop />
          <ThemeProvider theme={theme}>
            <div style={{ position: 'relative', minHeight: '100vh' }}>
              <AlertList />
              <ConfirmationModal />
              <AppBar />
              <Navigation />
            </div>
          </ThemeProvider>
        </BrowserRouter>
      </Provider>
    );
  }
}
