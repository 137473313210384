/*
* Copyright © 2021 Lexcelon LLC <info@lexcelon.com>
* Licensed for non-distributable use
*/
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { removeConfirmation } from '../redux/actions/confirmActions';

// Components
import { Button } from '@lexcelon/react-util';

function ConfirmationModal({ confirm, removeConfirmation }) {
  return (
    <Dialog
      open={confirm.title != null}
      onClose={() => {
        if (confirm.onClose) confirm.onClose();
        removeConfirmation();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      disableEscapeKeyDown={confirm.isLoading}
    >
      {confirm.title &&
      <DialogTitle id="alert-dialog-title">{confirm.title}</DialogTitle>}

      {confirm.body &&
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {confirm.body}
          </DialogContentText>
        </DialogContent>}

      <DialogActions>
        <Button secondary autoFocus={confirm.danger} onClick={() => {
          if (confirm.onClose) confirm.onClose();
          removeConfirmation();
        }} disabled={confirm.isLoading}>
          Cancel
        </Button>
        <Button onClick={confirm.onConfirm} autoFocus={!confirm.danger} isLoading={confirm.isLoading} danger={confirm.danger}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationModal.propTypes = {
  confirm: PropTypes.object.isRequired,
  removeConfirmation: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  confirm: state.confirm
});

const mapDispatchToProps = dispatch => ({
  removeConfirmation: () => dispatch(removeConfirmation())
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);
