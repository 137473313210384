import React from 'react';
import { Redirect } from 'react-router-dom';

export default function AuthRedirect(pathname, authProps) {
  const { isLoading, isAuthenticated } = authProps;
  pathname = pathname.toLowerCase();

  if (!isLoading) {
    // When logged in, you should only be able to access /admin pages
    if (isAuthenticated) {
      if (pathname.startsWith('/auth') || pathname.startsWith('/invoices')) return <Redirect to='/' />;
    }

    // When logged out, you should only be able to access /auth and /invoices pages
    else {
      if (pathname.startsWith('/admin')) return <Redirect to='/' />;
    }
  }
  return null;
}
