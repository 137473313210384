import store from '../redux/store';
import { clearErrors as reduxClearErrors, setAlert as reduxSetError, setSuccess as reduxSetSuccess, removeAlert as reduxRemoveAlert } from '../redux/actions/alertActions';

export function setSuccess(...props) {
  reduxSetSuccess(store?.dispatch, ...props);
}

export function clearErrors() {
  store?.dispatch(reduxClearErrors());
}

export function setError(...props) {
  reduxSetError(store?.dispatch, ...props);
}

export function removeAlert(...props) {
  store?.dispatch(reduxRemoveAlert(...props));
}
